import { type ActionFunction, redirect } from "react-router";
import { UtilLayout } from "./layout";

export const action: ActionFunction = async (_) => {
  // Provide default action to not log errors on random vulnerability scanners
  return redirect("/");
};

export default function NotFoundPage() {
  return <UtilLayout>Sorry, there's nothing here.</UtilLayout>;
}
